import React, { useState, useContext, useEffect } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row, Col,
  PopoverHeader,
  PopoverBody, UncontrolledPopover
} from 'reactstrap';
import PropTypes from 'prop-types';

import { Context } from "../Context";

import { BiInfoCircle } from 'react-icons/bi';
import { HiArrowCircleDown } from 'react-icons/hi';
import { ImSpinner2 } from 'react-icons/im';
import { FaTelegramPlane, FaTiktok, FaYoutube } from 'react-icons/fa';
import { IoLogoGooglePlaystore, IoLogoFacebook, IoLogoTwitter, IoLogoInstagram } from "react-icons/io5";


import {formatDate} from '../Utils';

const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVER;

const Home = ({ children, direction, updatedDate=true, ...args}) => {
    
  // Context
    const {list, setList} = useContext(Context);
    const  { precio, setPrecio } = useContext(Context);
    const {tasaBcv, setTasaBcv} =  useContext(Context);

    const {dropdownTitle, setDropdownTitle} = useContext(Context);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleClick = (i) => {
        // console.log(i);
        setDropdownTitle(i)
        setPrecio(i.price)
    };


  return (
    <>
    {precio ? (
      <>
      <Row className='prc'>
          <Col sm={12}>
              <>
                  {children}
              </>
              {updatedDate ? (
                  <span>
                  {dropdownTitle ? 
                  (<>{formatDate(dropdownTitle?.updatedAt)}</>) : 
                  (<>{formatDate(tasaBcv?.updatedAt)}</>)}
                  <button id="PopoverFocus" type="button"><BiInfoCircle/></button>

                  <UncontrolledPopover
                  placement="bottom"
                  target="PopoverFocus"
                  trigger="focus"
                  >
                      <PopoverHeader>
                          ¡Aviso!
                      </PopoverHeader>
                      <PopoverBody>
                          Las tasas se actualizan todos los días a las 9 a.m. y a la 1 p.m. Hora Venezuela. <a href='https://criptodolar.net/'>Fuente</a>.
                      </PopoverBody>
                  </UncontrolledPopover>
                  </span> 
                  ) : <></>}

          </Col>
      </Row>
      <Row className='prc'>
          <Col sm={12}>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction} className="price_content">
                  <DropdownToggle style={{width: '220px'}}>  
                  {dropdownTitle ? 
                  (<><img src={dropdownTitle?.icon} /> {dropdownTitle?.name}</>) : 
                  (<><img src={tasaBcv?.icon} /> {tasaBcv?.name}</>)}
                  <HiArrowCircleDown style={{marginLeft:'10px'}}/>
                  </DropdownToggle>
                  <DropdownMenu {...args} style={{width: '220px'}}>
                      {list.map( (item) => (
                      <DropdownItem key={item._id} onClick={() => handleClick(item)}>
                      <img src={item.icon} />
                      {item.name}
                      </DropdownItem>
                      ))}
                  </DropdownMenu>
              </Dropdown>
          </Col>

        <Col sm={12} className='social-networks-section'>
            <br />
            <div className='social-networks'>
                <a href="https://play.google.com/store/apps/details?id=com.tasadia.twa" target="_blank"><IoLogoGooglePlaystore className='s-icon'/></a>
                <a href="https://www.instagram.com/tasa.dia/" target="_blank"><IoLogoInstagram className='s-icon'/></a>
                <a href="https://twitter.com/TasadiaCom" target="_blank"><IoLogoTwitter className='s-icon'/></a>
                <a href="https://www.tiktok.com/@tasa.dia" target="_blank"><FaTiktok className='s-icon'/></a>
                <a href="https://www.youtube.com/channel/UC92_1MIzpJlSk4wcrZYUqcw" target="_blank"><FaYoutube className='s-icon'/></a>
                {/* <a href="https://t.me/tasadiacom" target="_blank"><FaTelegramPlane className='s-icon'/></a> */}
                {/* <a href="https://www.facebook.com/profile.php?id=100091990814990" target="_blank"><IoLogoFacebook className='s-icon'/></a> */}
            </div>
        </Col>
      </Row>
    </>
    ) : 
    <Row className='prc'>
        <ImSpinner2 className="loading-icon"/>
    </Row>}





    </>
  );
};

export default Home;

import React, { useState, useContext, useLayoutEffect,  } from 'react';
import { Card,
    CardHeader,
    ListGroup,
    ListGroupItem, 
    Col,Row,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    Table,
    Button
         } from 'reactstrap';
import { Context } from "../Context";

import { BsPlusLg } from "react-icons/bs";
import { BiInfoCircle, BiTrash } from 'react-icons/bi';

import { getTotalBolivar, getTotalDolar, truncFloat } from "../Utils";

// CELL -----------------------------------------

const itemStyle = {
    padding: '0px',
    position: 'relative',
    height: '26px',
    display: 'flex',
    alignItems: 'center'
  };
  
  const textStyle = {
    ...itemStyle,
    padding: '0px 4px',
    height: '26px',
    fontFamily: 'Arial',
    fontSize: '13px'
  };
  
  const inputStyle = {
    padding: '0',
    position: 'absolute',
    left: '2px',
    top: '2px',
    right: '45px',
    bottom: '2px',
    minWidth: '20px',
    fontFamily: 'Arial',
    fontSize: '13px'
  };
  
  const buttonStyle = {
    position: 'absolute',
    top: '2px',
    right: '2px',
    bottom: '2px',
    width: '40px'
  };
  
  const TCell = React.memo(({value, mode, onChange}) => {
    const [localMode, setLocalMode] = React.useState(mode ?? 'read');
    const [localValue, setLocalValue] = React.useState(value ?? '');
    React.useEffect(() => setLocalMode(mode ?? 'read'), [mode]);
    React.useEffect(() => setLocalValue(value ?? ''), [value]);
  if (localMode === 'edit') {
        const handleInputChange = (e) => setLocalValue(e.target.value);
        const handleSaveClick = () => {
            setLocalMode('read');
            onChange?.(localValue);
            // console.log(localValue) // Este es el valor nuevo MODIFICADO
        };
        return (
            // Este es el que muestra y maneja el input cuando se está editando
            <div style={itemStyle}>
              <input type="text"
                value={localValue}
                style={inputStyle}
                onChange={handleInputChange}
                onBlur={handleSaveClick}
                 />
              {/* <button style={buttonStyle} onClick={handleSaveClick}>Ok</button> */}
            </div>
        );
    }
    if (localMode === 'read') {
        const handleEditClick = () => {
            setLocalMode('edit');
        };
        return (
            // ESTE ES EL QUE RETORNA el div editado y espera el click
            <div style={textStyle} onClick={handleEditClick}>{localValue}</div>
        );
    }
    return null;
  });
  
  // ROW ------------------------------------------
  

  
  const optionStyle = {
    padding: '2px 2px',
    width: '30px'
  };
  
  const TRow = React.memo(({ mode, columns, data, onChange, onDelete}) => {
  const handleDeleteClick = () => onDelete?.();
    return (
        <tr>
          {columns.map(({path}, columnIndex) => {
              const handleChange = value => {
                  if (onChange) {
                      const changedData = { ...data, [path]: value };
                      onChange(columnIndex, changedData);
                      // console.log(changedData.bolivar)
                  }
                  // POR AQUI VA LA COSA
              };
              return (
                  <td key={path}>
                    <TCell 
                      mode={mode}
                      value={data[path]} 
                      onChange={handleChange} 
                    />
                  </td>
              );
          })}
          <td style={optionStyle}>
            <Button 
            color="danger"
            size="sm"
            onClick={handleDeleteClick}><BiTrash/></Button>
          </td>
        </tr>
    );
  });
  
  // TABLE ----------------------------------------
  
  const TTable = React.memo(({id, columns, data, onAdd, onChange, onDelete}) => {
    const [addedIndex, setAddedIndex] = React.useState();
    const handleAddClick = () => {
      onAdd?.(data.length);
        setAddedIndex(data.length);
    };
  return (
        <div>
          <Table
            className='compra-table'
             >
                <thead>
                  <tr>
                      {/* {columns.map(({path, name}) => (
                        <th key={path} scope="row"
                        //  style={tdStyle}
                        >{name}</th>
                      ))} */}
                  <th>$</th>
                  <th colSpan={2}>Bs.</th>
                  </tr>
                </thead>
              <tbody>
              {data.map((rowData, rowIndex) => {
                  const handleChange = (columnIndex, changedData) => {
                      onChange?.(rowIndex, columnIndex, changedData);
                  };
                  const handleDelete = () => {
                      if (rowIndex !== addedIndex) {
                          setAddedIndex(addedIndex - 1);
                      }
                      onDelete?.(rowIndex, rowData);
                  };
                  return (
                      <TRow
                        key={rowData[id]}
                        mode={addedIndex === rowIndex ? 'edit' : 'read'}
                        columns={columns}
                        data={rowData}
                        onChange={handleChange}
                        onDelete={handleDelete}
                      />
                  );
              })}
            </tbody>
          </Table>
          <br />
          <div>

          <span className="float-nav-2" onClick={handleAddClick} ><BsPlusLg/></span>

          </div>
        </div>
    );
  });
  
  // UTILS ----------------------------------------
  
  // https://dirask.com/snippets/React-append-prepend-remove-and-replace-items-in-array-with-utils-for-useState-D7XEop
  
  const appendItem = (updater, item) => {
    updater(array => array.concat(item));
  };
  
  const replaceItem = (updater, index, item) => {
    updater(array => array.map((value, i) => i === index ? item : value));
  };
  
  const deleteItem = (updater, index) => {
    updater(array => array.filter((value, i) => i !== index));
  };
 
  const SumListas = (updater, array) => {
    var totalDiv = array.reduce((sum, value) => (typeof parseFloat(value.divisa) == "number" ? parseFloat(sum) + parseFloat(value.divisa): parseFloat(sum)), 0);
    var totalBs = array.reduce((sum, value) => (typeof parseFloat(value.bolivar) == "number" ? parseFloat(sum) + parseFloat(value.bolivar) : parseFloat(sum)), 0);
    updater({divisa:truncFloat(totalDiv),bolivar:truncFloat(totalBs)});
};

  // Example --------------------------------------
  
  const columns = [
    { path: 'divisa', name: '$' },
    { path: 'bolivar',  name: 'Bs.'  }
  ];
  
  let counter = 0;
  
  const Compras = () => {
    const  { precio, setPrecio } = useContext(Context);
    const cambioInitialSt = {divisa: 0,  bolivar: 0 };
    const  [ cambio, setCambio] = useState(cambioInitialSt);
    const [data, setData] = useState(() => ([
        {id: 0, divisa: 0,  bolivar: 0 }
    ]));

    
    const handleAdd = (rowIndex) => {
        const newRowData =  { 
          id: ++counter,
          divisa: 0,
          bolivar: 0
         };
        appendItem(setData, newRowData);
        //TODO: AJAX request to server
        console.log(`Added empty row!`);
    };

    const handleChange = (rowIndex, columnIndex, changedRowData) => {
        if (columnIndex===0){
        changedRowData.bolivar = getTotalBolivar(changedRowData.divisa, precio)}

        if (columnIndex===1){
        changedRowData.divisa = getTotalDolar(changedRowData.bolivar, precio)}

        replaceItem(setData, rowIndex, changedRowData);

        const changedRowJson = JSON.stringify(changedRowData, null, 4);
        //TODO: AJAX request to server
        console.log(`Changed row:\n${changedRowJson}`);
    };

    const handleDelete = (rowIndex, deletedRowData) => {
        deleteItem(setData, rowIndex);
        //TODO: AJAX request to server
        if (Object.keys(data).length <= 1){setCambio(cambioInitialSt);}
        console.log(`Deleted row: ${rowIndex}`);
    };

    const refreshTable = () => {
        data.map((value, i) => {
            value.bolivar =  getTotalBolivar(value.divisa, precio)
            SumListas(setCambio, data);
        });
    }

    useLayoutEffect(() => {
        refreshTable();
    }, [precio]);
    
    
    return (
    <>
        <Row style={{textAlign : 'center'}}>
            <Col className='tasa-combustible-blocked'>
                <span><h4>Tasa: <b>{precio} Bs.</b></h4></span>
            </Col>
        </Row>
        <br /><br />
        <Row style={{textAlign : 'center'}}>
        <span style={{marginTop: '-23px', marginBottom: '40px'}}>
                <h3 style={{display: 'inline'}}> Total a pagar</h3>
                <button id="PopoverFocusCompras" type="button" style={{all: 'unset'}}><BiInfoCircle/></button>
                <UncontrolledPopover
                placement="bottom"
                target="PopoverFocusCompras"
                trigger="focus"
                >
                    <PopoverHeader>
                        ¡Aviso!
                    </PopoverHeader>
                    <PopoverBody>
                        Los totales presentados no incluyen IVA u otros impuestos. ¡Tomar SOLO como referencia!
                    </PopoverBody>
                </UncontrolledPopover>
            </span>

            <Col>
            <span><h4><b>{cambio.divisa} $</b></h4></span>
            </Col>
            <Col>
            <span><h4><b>{cambio.bolivar} Bs.</b></h4></span>
            </Col>
        </Row>
        <br /><br />
        <TTable
        id="id"
        columns={columns}
        data={data}
        onAdd={handleAdd}
        onChange={handleChange}
        onDelete={handleDelete}
        />
    </>
    );
  };

export default Compras;




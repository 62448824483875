
// function truncFloat (x, posiciones = 0) {
//     var s = x.toString()
//     var l = s.length
//     var decimalLength = s.indexOf('.') + 1

//     if (l - decimalLength <= posiciones){
//     return x
//     }
//     // Parte decimal del número
//     var isNeg  = x < 0
//     var decimal =  x % 1
//     var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
//     // Parte decimal como número entero
//     // Ejemplo: parte decimal = 0.77
//     // decimalFormated = 0.77 * (10^posiciones)
//     // si posiciones es 2 ==> 0.77 * 100
//     // si posiciones es 3 ==> 0.77 * 1000
//     var decimalFormated = Math.floor(
//     Math.abs(decimal) * Math.pow(10, posiciones)
//     )
//     // Sustraemos del número original la parte decimal
//     // y le sumamos la parte decimal que hemos formateado
//     var finalNum = entera + 
//     ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))

//     return finalNum
// }


function truncFloat(n) {
    let t=n.toString();
    let regex=/(\d*.\d{0,2})/;
    return t.match(regex)[0];
  }
  
function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
        return new Date(
        new Date(date).toLocaleString('es-US', {
            timeZone,
        }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
        timeZone,
        }),
    );
}

function getPrice(divisa) {return truncFloat(parseFloat(divisa))}

function getTotalBolivar(divisa, precio) {

    return truncFloat(parseFloat((divisa * precio))) }

function getTotalDolar(bolivar, precio) {return truncFloat(parseFloat((bolivar / precio)))}


function formatDate(dateString){
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
  } 


  const getUserAgent = () => {
    let mobile = false;
    let navegador = navigator.userAgent;
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        console.log("Estás usando un dispositivo móvil!!");
        mobile = true;

      } else {
        console.log("No estás usando un móvil");
        mobile = false;
      }


    // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // // console.log("userAgent ", userAgent );
    // const localStorageAgent = localStorage.getItem('user-agent');
    // return userAgent;

    return mobile;

  };  

  function toFixed( num, precision ) {
    // Retorna el valor con el número de decimales selecionado, sin redondearlo
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  }





export {getPrice, getTotalBolivar, 
        getTotalDolar, truncFloat, 
        changeTimeZone, formatDate,
        getUserAgent, toFixed };
import { React, useState, useContext, useLayoutEffect } from 'react';
import { Form, FormGroup, Label, Input, Col
         } from 'reactstrap';
import { Context } from "../Context";

import { getPrice, getTotalBolivar, getTotalDolar, truncFloat } from "../Utils";


const Convertidor = () => {
    // Context
    const  { precio, setPrecio } = useContext(Context);
    const  [ divisa, setDivisa ]  = useState(1.00);
    const  [ bolivar, setBolivar ] = useState(precio);

    const handlePriceChange = (e) => {
        setDivisa(e.target.value)
        setBolivar(() => getTotalBolivar(divisa, precio))
    };

    const handleBolivarChange = (e) => {
        setBolivar(e.target.value)
        setDivisa(getTotalDolar(bolivar,precio))
    };

    useLayoutEffect(() => {
        setBolivar(precio)
        setBolivar(getTotalBolivar(divisa, precio))
    }, [precio]);


    return (
    <>
        <Form>
            <FormGroup row>
                <Label for="divisaID" sm={1} > $ </Label>
                <Col sm={11} className='webflow-style-input'>
                    <input 
                    id="divisaID" name="price_dolar" 
                    placeholder="Dólares" 
                    type="txt" 
                    value={divisa}
                    onChange={handlePriceChange}
                    onBlur={() => setDivisa(getPrice(divisa))}
                    onKeyUp={ () =>  setBolivar(getTotalBolivar(divisa, precio))}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="bolivarID" sm={1}> Bs. </Label>
                <Col sm={11} className='webflow-style-input'>
                    <input 
                    id="bolivarID" name="price_bolivar" 
                    placeholder="Bolívares" type="txt" 
                    value={bolivar}
                    onChange={handleBolivarChange}
                    onKeyUp={ () => setDivisa(getTotalDolar(bolivar,precio))}
                    onBlur={ () =>  setBolivar(truncFloat(parseFloat(bolivar)))}
                    />
                </Col>
            </FormGroup>
        </Form>
        <br />
    </>
    );
};

export default Convertidor;
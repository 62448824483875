import { React, useState, useContext, useLayoutEffect, useEffect } from 'react';
import { Form, FormGroup, Label, Input, FormText, Col, Button, Row
         } from 'reactstrap';
import { Context } from "../Context";


const About = () => {

    return (
    <>
    <Row>
        <Col sm='12'>
            <div className='p_parrafo'>
                <div className='p_titulo'>Muchas tasas, un solo lugar. ¿Por qué complicarse?</div>
            </div>

            <div className='p_parrafo'>
            ¡Con Tasadia, nunca ha sido tan fácil estar al tanto del precio del dólar en tiempo real! Esta práctica aplicación te proporciona información precisa y actualizada sobre las tasas de cambio del dólar, para que siempre puedas estar informado.
            </div>


            <div className='p_parrafo'>
                <div className='p_titulo'>Características clave:</div>
                <ul>
                    <li>Muestra el precio de las tasas del dólar en tiempo real.</li>
                    <li>Utiliza información precisa y actualizada de fuentes confiables.</li>
                    <li>Fácil de usar y con una interfaz simple y atractiva.</li>
                    <li>Te permite realizar un seguimiento de tus tasas favoritas.</li>
                    <li>Calculadora de precios fácil de usar.</li>
                    <li>Página web <a href='https://tasadia.com' target="_blank">tasadia.com</a>. </li>
                    <li>Puedes instalar Tasadida también en tu computadora desde la página de <a href='https://tasadia.com' target="_blank">tasadia.com</a>.</li>
                </ul>
            </div>

            <div className='p_parrafo'>
                <div className='p_titulo'>¿Por qué elegir Tasadia?</div>
                Con Tasadia, tendrás acceso a la información más precisa y actualizada sobre las tasas del dólar. 
                Las tasas mas populares en Venezuela en la palma de tus manos, lo que te permitirá tomar decisiones informadas y estratégicas. 
                Queremos hacer el día a día Venezolano un poquito mas facil. Ya sea que estés haciendo negocios, en el mercado o simplemente quieras estar al tanto de las fluctuaciones, Tasadia es la aplicación que necesitas.
            </div>

            <div className='p_parrafo'>
                ¡Es fácil, práctico y completamente gratis!
            </div>
        </Col>
    </Row>
    <br />
    <Row>
        <Col sm='12'>
            <h2>Política de privacidad</h2>

            <p style={{textAlign:'justify', textJustify:'auto'}} >

            <div className='p_parrafo'>
                <div className='p_titulo'>Última actualización: 2023-03-08</div>
            </div>

            <div className='p_parrafo'>
                Esta política de privacidad describe cómo se recopila, utiliza y comparte la información que se recopila cuando se utiliza la aplicación Tasadia ("la aplicación").
            </div>

            <div className='p_parrafo'>
                <div className='p_titulo'>Recopilación de información</div>
                La aplicación no recopila ninguna información personal identificable del usuario, como su nombre o dirección de correo electrónico. Sin embargo, la aplicación puede recopilar cierta información no personal, como el tipo de dispositivo que se está utilizando, la versión del sistema operativo, el tipo de navegador y el idioma de preferencia.
            </div>

            <div className='p_parrafo'>
                <div className='p_titulo'>Uso de la información</div>
                La información recopilada se utiliza únicamente para mejorar la calidad y funcionalidad de la aplicación. No se venderá, alquilará ni compartirá la información con terceros sin el consentimiento explícito del usuario.
            </div>

            <div className='p_parrafo'>
                <div className='p_titulo'>Cookies y tecnologías similares</div>
            La aplicación no utiliza cookies ni tecnologías similares para recopilar información sobre el usuario.
            </div>

            <div className='p_parrafo'>
                <div className='p_titulo'>Enlaces a sitios web de terceros</div>
                La aplicación puede contener enlaces a sitios web de terceros. No somos responsables de las prácticas de privacidad o del contenido de estos sitios web de terceros.
            </div>

            <div className='p_parrafo'>
                <div className='p_titulo'>Cambios en la política de privacidad</div>
                Podemos actualizar esta política de privacidad de vez en cuando. Le recomendamos que revise esta página periódicamente para estar al tanto de cualquier cambio en nuestra política de privacidad.
            </div>

            <div className='p_parrafo'>
                Al utilizar la aplicación, usted acepta los términos de esta política de privacidad. Si no está de acuerdo con esta política, no use la aplicación.
            </div>
            </p>
        </Col>
    </Row>
    <br />
    <Row>
        <Col sm='12'>
            <h2>Descargo de responasbilidad</h2>
            <div className='p_parrafo'>
                <div className='p_titulo'>No somos una casa de cambio</div>
                Tasadia nace como una herramienta para brindar una forma cencilla de encontrar las tasas de las fuentes que YA existen y 
                nuestro compromiso es mantener siempre la información mas confiable y precisa, sin embargo, no cotizamos, o especulamos con ninguna tasa. 
                Solo usamos las tasas de sitios que ya existen en el mercado. 
            </div>
        </Col>
    </Row>
    <br /><br /><br /><br /><br />
    </>
    );
};

export default About;

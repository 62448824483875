import React, { useState } from "react";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {

    const  [precio, setPrecio ] = useState(0);
    const  [lastUpdate, setLastUpdate ] = useState(0);
    const [tasaBcv, setTasaBcv] = useState([]);
    const [dropdownTitle, setDropdownTitle] = useState(false);
    const [list, setList] = useState([]);
    const [oil, setOil] = useState([]);
    const [maintenanceWindow, setMaintenanceWindow] = useState(false);

    return (
        <Context.Provider 
            value={{ 
                precio, setPrecio, 
                lastUpdate, setLastUpdate,
                tasaBcv, setTasaBcv, 
                dropdownTitle, setDropdownTitle,
                list, setList,
                oil, setOil,
                maintenanceWindow, setMaintenanceWindow
                 }}>
            {children}
        </Context.Provider>
    );
};
import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from './contexts/theme-context';
import { BrowserRouter } from "react-router-dom";
// import Router from "./routes";
import axios from 'axios';

import Layout from './layout';

import Menu from './components/Menu'
import Mantenimiento from './pages/Mantenimiento'

import moon from './images/moon.png';
import sun from './images/sun.png';

import { getUserAgent } from './Utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { Context } from "./Context";

const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVER;



function AppWrapper(userAgent) {
  // Context
  const { precio, setPrecio } = useContext(Context);
  const {tasaBcv, setTasaBcv} =  useContext(Context);
  const {list, setList} = useContext(Context);
  const {oil, setOil} = useContext(Context);
  const {maintenanceWindow, setMaintenanceWindow} = useContext(Context);

  useEffect(() => {
    axios({
      url: BACKEND_URL + "/api/list/",
    })
      .then((response) => {
        setList(response.data[0]);
        setOil(response.data[1][0])
        // Filtra tasa BCV
        var filtered = response.data[0].filter(a => a.symbol === "VES");
        setTasaBcv(filtered[0]);
        setPrecio(filtered[0].price);
        setMaintenanceWindow(response.data[2][0]);
      })
      .catch((error) => {
        console.log(error);
        setMaintenanceWindow(true);
      });
  }, []);


  return (
    <Layout userAgent={userAgent} >
      {/* <div className="content-wrapper" style={{border:'1px solid red'}}> */}
      <div className="content-wrapper">
      <Menu isMobil={userAgent.userAgent} />
      </div>
    </Layout>
  )
  

}  

function App() {
    // Context
    const {maintenanceWindow, setMaintenanceWindow} = useContext(Context);
    // Detecting the default theme
    const isBrowserDefaulDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

    const getDefaultTheme = () => {
      const localStorageTheme = localStorage.getItem('default-theme');
      const browserDefault = isBrowserDefaulDark() ? 'dark' : 'light';
      return localStorageTheme || browserDefault;
    };  


    const [theme, setTheme] = useState(getDefaultTheme());

    const [agent, setAgent] = useState(getUserAgent());
    
    return (
      <ThemeContext.Provider value={{ theme, setTheme }}>
        {/* <BrowserRouter> */}
        {
          maintenanceWindow ? (
          <> 
          <Mantenimiento/>
          </>
          ): (
            <div className={`theme-${theme}`}>
              <AppWrapper userAgent={agent}/>
            </div>
          )
        }
        {/* </BrowserRouter> */}
      </ThemeContext.Provider>
  );
}

export default App;

import React, { useState } from "react";

import Home from '../pages/Home';
import Moneda from '../pages/Moneda';
import Convertidor from '../pages/Convertidor';
import Combustible from "../pages/Combustible";
import Compras from "../pages/Compras";
import About from "../pages/About";


import {
    TabContent, TabPane, Nav,Row,Col
    } from 'reactstrap';

import classnames from 'classnames';

import { RiPlayListAddFill } from "react-icons/ri";
import { FaChartLine,FaGasPump,FaCalculator, FaUsers } from "react-icons/fa";
import { useEffect } from "react";


const MenuContnet = (currentActiveTab) =>{

    // State for current active Tab
    const [activeTab, setCActiveTab] = useState('1');

    useEffect(() => {
        setCActiveTab(currentActiveTab.currentActiveTab);
      }, [currentActiveTab]);

    return (
        <>
        <Col sm={12}>
            {console.log(activeTab)}
            {/* <TabContent activeTab={currentActiveTab} className="align-self-center" style={{border:'1px solid green'}}> */}
            <TabContent activeTab={currentActiveTab.currentActiveTab} className="align-self-center">
                <TabPane tabId="1">
                    <Home>
                        <Moneda />
                    </Home>
                </TabPane>
                <TabPane tabId="2">
                    <Home>
                        <Convertidor/>
                    </Home>
                </TabPane>
                <TabPane tabId="3">
                    <Combustible/>
                </TabPane>
                <TabPane tabId="4">
                    <Home updatedDate={false} /> <br /><br />
                    <Compras/>
                </TabPane>
                <TabPane tabId="5">
                    <About/>
                </TabPane>
            </TabContent>
        </Col>
        </>
    );
}


const Menu = (isMobil) =>{

    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    const [selectedOption, setSelectedOption] = useState('option1');
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    }


    return (
        <>
        {isMobil.isMobil ? (
            <>
                <Row>
                    <MenuContnet currentActiveTab={currentActiveTab}/>
                </Row>
                <div className='mb-menu'>
                    <div id="box"></div>
                    {/* <div id="mb-menu-body"></div> */}

                    <input type="radio"
                    id="one" 
                    name="menu" 
                    value="option1"
                    checked={ currentActiveTab === '1' ? "checked" : "" }
                    onChange={handleOptionChange}
                    className={classnames({ active: currentActiveTab === '1' })} onClick={() => { toggle('1'); }}
                    />
                    <label for="one" className="icons mb-menu-01"><span ><FaChartLine/></span></label>
                    <span  className="title mb-menu-01">Precio</span>

                    <input 
                    type="radio"
                    id="two" 
                    name="menu"
                    value="option2"
                    // checked={ currentActiveTab === '1' ? "checked" : "" }
                    onChange={handleOptionChange}
                    className={classnames({active: currentActiveTab === '2' })} onClick={() => { toggle('2'); }}
                    />
                    <label for="two" className="icons mb-menu-02"><span ><FaCalculator/></span></label>
                    <span className="title mb-menu-02">Convertidor</span>

                    <input 
                    type="radio" 
                    id="three" 
                    name="menu"
                    value="option3"
                    // checked={ currentActiveTab === '1' ? "checked" : "" }
                    onChange={handleOptionChange}
                    className={classnames({ active: currentActiveTab === '3' })} onClick={() => { toggle('3'); }}
                    />
                    <label for="three" className="icons mb-menu-03"><span ><FaGasPump/></span></label>
                    <span className="title mb-menu-03">Combustible</span>

                    <input 
                    type="radio" 
                    id="four" 
                    name="menu"
                    value="option4"
                    // checked={ currentActiveTab === '1' ? "checked" : "" }
                    onChange={handleOptionChange}
                    className={classnames({ active: currentActiveTab === '5' })} onClick={() => { toggle('5'); }}/>
                    <label for="four" className="icons mb-menu-04"><span ><FaUsers/></span></label>
                    <span className="title mb-menu-04">Nosotros</span>

                    <div className="border"></div>
                    <div className="effect"></div>
                </div>
            </>
        ) : 
        (
            <>
            <Row>
                <Nav>
                    <div className="menu">
                        <div className={classnames({ active: currentActiveTab === '1' })} onClick={() => { toggle('1'); }}>
                            <input className="menu_input" id="btn1" name="menu" type="radio" checked={ currentActiveTab === '1' ? "checked" : "" } />
                            <label className="menu_label" for="btn1">
                                <div>
                                    <FaChartLine/>
                                </div>
                                <div className="menu_text">
                                    Precio actual
                                </div>
                            </label>
                            {/* <div className="menu_box">
                            <div className="menu_list">
                            About Us
                            </div>
                            <div className="menu_list">
                            Contact Us
                            </div>
                            </div> */}
                        </div>
                        <div className={classnames({active: currentActiveTab === '2' })} onClick={() => { toggle('2'); }}>
                            <input className="menu_input" id="btn2" name="menu" type="radio" />
                            <label className="menu_label" for="btn2">
                                <div>
                                    <FaCalculator/>
                                </div>
                                <div className="menu_text">
                                    Convertidor
                                </div>
                            </label>
                        </div>
                        <div className={classnames({ active: currentActiveTab === '3' })} onClick={() => { toggle('3'); }}>
                            <input className="menu_input" id="btn3" name="menu" type="radio" />
                            <label className="menu_label" for="btn3">
                                <div>
                                    <FaGasPump/>
                                </div>
                                <div className="menu_text">
                                    Combustible
                                </div>
                            </label>
                        </div>
                        {/* <div className={classnames({ active: currentActiveTab === '4' })} onClick={() => { toggle('4'); }}>
                            <input className="menu_input" id="btn4" name="menu" type="radio" />
                            <label className="menu_label" for="btn4">
                                <div>
                                    <RiPlayListAddFill/>
                                </div>
                                <div className="menu_text">
                                    Lista de compras
                                </div>
                            </label>
                        </div> */}

                        <div className={classnames({ active: currentActiveTab === '5' })} onClick={() => { toggle('5'); }}>
                            <input className="menu_input" id="btn5" name="menu" type="radio" />
                            <label className="menu_label" for="btn5">
                                <div>
                                    <FaUsers/>
                                </div>
                                <div className="menu_text">
                                    Nosotros
                                </div>
                            </label>
                        </div>
                    </div>
                </Nav>

                <MenuContnet currentActiveTab={currentActiveTab}/>

                {/* <Router /> */}
                {/* <img src={theme === 'dark' ? moon : sun} alt={theme === 'dark' ? 'moon' : 'sun'} /> */}
            </Row>
            <br /><br /><br />

            </>
        )}
        </>

    );
}


export default Menu;

// PAra pasar los datos del hijo al padre con hooks
// https://adrianfdez469.medium.com/keep-react-child-state-on-the-child-if-possible-d531f0715408
// https://blog.logrocket.com/underrated-react-hooks-youre-missing-out-on/
// https://github.com/topics/useimperativehandle
// https://github.com/mouhsineelachbi/react_js_hooks_with_examples/blob/useImperativeHandle/src/App.js
import { React, useState, useContext, useEffect } from 'react';
import { Form, FormGroup, Label, Input,Col, Row } from 'reactstrap';
import { Context } from "../Context";


const Combustible = () => {
    const {tasaBcv, setTasaBcv} =  useContext(Context);
    const {oil, setOil} = useContext(Context);

    // Variables temporales
    // Tasas sacadas de aquí: https://www.somoscladc.com.ve/2020/05/fue-establecido-oficialmente-el-aumento.html
    // const jTasas = {
    //     "internacional": 0.5,
    //     "subsidiada": 0.025,
    // }

    const  [ litros, setLitros ] = useState(1);
    const  [ tasa, setTasa ] = useState(0);

    // Cálculo de tasas
    function getTotalDolar() {return parseFloat((litros * tasa)).toFixed(2)}
    function getTotalBolivar() {return parseFloat((litros * tasa * tasaBcv.price)).toFixed(2)}
    // Manejo de inputs
    const handleInputChange = (e) => {setLitros(e.target.value)}
    const handleRadioChange = (e) => {setTasa(e.target.value)}
    
    useEffect(() => {
        setTasa(oil.internacional)
      }, [oil]);


    return (
    <>
        <Form>
        <FormGroup row>
                <Label for="LitroID" sm={2} > Litro(s) </Label>
                <Col sm={10} className='webflow-style-input'>
                    <input 
                    id="LitroID" name="litros" 
                    placeholder="Litros de combustible" type="txt" 
                    value={litros}
                    onChange={handleInputChange}
                    />
                </Col>
            </FormGroup>

            <Row style={{textAlign : 'center'}}>
                {/* <Col className='combustible-radio-button-center'>
                <FormGroup check className='combustible-radio-button' >
                    <Input
                    name="radio1"
                    type="radio"
                    value={oil.internacional}
                    onChange={handleRadioChange}
                    />
                    <Label check>Internacional</Label>
                </FormGroup>
                </Col>
                <Col>
                <FormGroup check className='combustible-radio-button'>
                    <Input
                    name="radio1"
                    type="radio"
                    value={oil.subsidiada}
                    onChange={handleRadioChange}
                    />
                    <Label check>Subsidiada</Label>
                </FormGroup>
                </Col> */}
            </Row>
            <br />
            <Row className='combustible-ref'><Col className='tasa-combustible-blocked'></Col></Row>
            <br />
            <Row style={{textAlign : 'center', backgroundColor: 'rgb(234, 234, 234)'}}>
                <Col className='tasa-combustible-blocked'>
                    Precio/Litro <h3>{tasa} $</h3> 
                </Col>
                <Col className='tasa-combustible-blocked'>
                    Tasa de referencia (BCV) <h3>{tasaBcv.price} Bs.</h3> 
                </Col>
            </Row>
            <br /><br /><br /><br />
            <Row style={{textAlign : 'center'}}><Col><h1>Total a pagar</h1></Col></Row>
            <Row style={{textAlign : 'center'}}>
                <Col>
                <span><h1><b>{getTotalDolar()} $</b></h1></span>
                </Col>
                <Col>
                <span><h1><b>{getTotalBolivar()} Bs.</b></h1></span>
                </Col>
            </Row>
        </Form>
    </>
    );
};

export default Combustible;

import React, { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import Menu from '../components/Menu';
import './styles.scss';
import { Container, Button } from 'reactstrap';
import { FaChartLine,FaGasPump,FaCalculator, FaUsers } from "react-icons/fa";

const Layout = ({ children, userAgent }) => {
    const [isReadyForInstall, setIsReadyForInstall] = useState(false);

    const [selectedOption, setSelectedOption] = useState('option1');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    }


    useEffect(() => {
      window.addEventListener("beforeinstallprompt", (event) => {
        // Prevent the mini-infobar from appearing on mobile.
        event.preventDefault();
        console.log("👍", "beforeinstallprompt", event);
        // Stash the event so it can be triggered later.
        window.deferredPrompt = event;
        // Remove the 'hidden' class from the install button container.
        setIsReadyForInstall(true);
      });
    }, []);
  
  
    async function downloadApp() {
      console.log("👍", "butInstall-clicked");
      const promptEvent = window.deferredPrompt;
      if (!promptEvent) {
        // The deferred prompt isn't available.
        console.log("oops, no prompt event guardado en window");
        return;
      }
      // Show the install prompt.
      promptEvent.prompt();
      // Log the result
      const result = await promptEvent.userChoice;
      console.log("👍", "userChoice", result);
      // Reset the deferred prompt variable, since
      // prompt() can only be called once.
      window.deferredPrompt = null;
      // Hide the install button.
      setIsReadyForInstall(false);
    }

return (
<div className="layout-wrapper" >
    <div className="main-wrapper">
        <Header />

        {/* <Container  className="d-flex"   style={{border:'1px solid red'}}> */}
        <Container  className="d-flex">
            {children}

            {isReadyForInstall && (
                <div className='wpa-download' style={{marginTop:'30px', textAlign:'center'}}>
                <h5> ¿Aún no la has Instalado? </h5>  
                <br />
                <Button color="success" onClick={downloadApp}>Instalar la App</Button>
                </div>
            )}
        </Container>
    </div>

      {
      // is a Mobil device?
      userAgent.userAgent ? (
        // <Menu isMobil={userAgent.userAgent} />
        <></>
        // <div> el nuevo menu mobil {userAgent.userAgent.toString()}</div>
        // console.log(userAgent.userAgent)
        ): 
        // Else
        // <Footer/>
        <Footer/>
      // console.log(userAgent.userAgent)
      // <div>el viejo menu mobil {userAgent.userAgent.toString()}</div>
      }
    

</div>
);
};

export default Layout;

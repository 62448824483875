import { React } from 'react';
import './styles.scss';



import { BiInfoCircle } from 'react-icons/bi';
import { HiArrowCircleDown } from 'react-icons/hi';
import { ImSpinner2 } from 'react-icons/im';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoLogoGooglePlaystore, IoLogoFacebook, IoLogoTwitter, IoLogoInstagram } from "react-icons/io5";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">

{/* <div className='social-networks'>
  <a href="https://play.google.com/store/apps/details?id=com.tasadia.twa" target="_blank"><IoLogoGooglePlaystore className='s-icon'/></a>
  <a href="https://www.instagram.com/tasa.dia/" target="_blank"><IoLogoInstagram className='s-icon'/></a>
  <a href="https://twitter.com/TasadiaCom" target="_blank"><IoLogoTwitter className='s-icon'/></a>
  <a href="https://t.me/tasadiacom" target="_blank"><FaTelegramPlane className='s-icon'/></a>
  <a href="https://www.facebook.com/profile.php?id=100091990814990" target="_blank"><IoLogoFacebook className='s-icon'/></a>
</div> */}


        &copy; {new Date().getFullYear()} <span>tasadia.com - {process.env.REACT_APP_VERSION_TAG}</span>
      </div>
    </footer>
  );
};

export default Footer;

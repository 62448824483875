import React, { useState, useContext, useEffect } from 'react';
import { Context } from "../Context";

import {toFixed} from '../Utils';

const Moneda = () => {
    // Context
    const  { precio, setPrecio } = useContext(Context);

    return (
    <>
        <p className='titulo2'>El <span>dólar</span> está en</p>
        <p>
            <>
            <span className='price_value'>{toFixed(precio,2)}</span>
            <span className='price_unit'>Bs.</span>
            </>
        </p>
    </>
    );
};

export default Moneda;
